import {CircularProgress} from "@mui/joy";
import * as React from "react";
import Box from "@mui/joy/Box";

export default function Loading() {
    return <Box sx={{display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress/>
    </Box>

}
