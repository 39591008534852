import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import Box from "@mui/joy/Box";
import * as React from "react";

export default function ErrorPage() {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mt:16}}>
            <Stack spacing={2}>
                <Typography textAlign="center" level="h2" component="h1">
                    Erro inesperado
                </Typography>
                <Typography textAlign="center" level="body-md" component="h1">
                    Tente novamente mais tarde ou entre em contado com o suporte.
                    <Link onClick={() => window.location.replace('/')} level="body-md">
                        Clique aqui para voltar
                    </Link>
                </Typography>
            </Stack>
        </Box>
    );
}