import axios from "axios";
import {getToken, saveToken} from "../hooks/useToken";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

const refreshAccessToken = async () => {
    const token = getToken();
    if (!token) {
        return null;
    }
    const value = {refreshToken: token.refresh_token};
    const url = process.env.REACT_APP_SERVER_URL + 'auth/refresh';
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(value)
        });
        if(response.ok) {
            return response.json();
        } else {
            window.location.href = '/login';
        }
    } catch (error) {
        if (error) {
            window.location.href = '/login';
        }
    }
}

axios.interceptors.request.use(
    async (config) => {
        const token = getToken();
        if (token && !config.url.includes('auth')) {
            config.headers.set('Authorization', token.token_type + ' ' + token.access_token);
        }
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const config = error?.config;
        if (error?.response?.status === 401 && !config?.url.includes('token') && !config?.sent) {
            config.sent = true;
            const newToken = await refreshAccessToken();

            if (newToken) {
                saveToken(newToken);
                config.headers = {
                    ...config.headers,
                    authorization: `${newToken.token_type} ${newToken.access_token}`,
                };
                return axios(config);
            }
        }
        return Promise.reject(error);
    });

export function fetcher(url) {
    return axios.get(url).then(res => res.data);
}
