import './App.css';
import {CssBaseline, CssVarsProvider, extendTheme} from "@mui/joy";
import {Outlet} from "react-router-dom";
import {useEffect, useLayoutEffect} from "react";
import useScript from "./hooks/useScript";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/pt-br';
import ErrorPage from "./components/ErrorPage";
import {ErrorBoundary} from "@sentry/react";
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    50: '#F0F0FA',
                    100: '#E0E0F5',
                    200: '#C1C1EB',
                    300: '#B2B2E6',
                    400: '#7474D2',
                    500: '#2F2C83',
                    600: '#26246B',
                    700: '#211F5C',
                    800: '#1B194D',
                    900: '#15143E',
                },
                neutral: {
                    50: '#F2F2F8',
                    100: '#E4E4F1',
                    200: '#D7D7EA',
                    300: '#CACAE3',
                    400: '#BCBCDC',
                    500: '#2A2A50',
                    600: '#232343',
                    700: '#1C1C35',
                    800: '#151528',
                    900: '#0E0E1B',
                }
            },
        },
        dark: {
            palette: {
                primary: {
                    50: '#F0F0FA',
                    100: '#E0E0F5',
                    200: '#C1C1EB',
                    300: '#B2B2E6',
                    400: '#A6A3E1',
                    500: '#2F2C83',
                    600: '#26246B',
                    700: '#211F5C',
                    800: '#1B194D',
                    900: '#15143E',
                },
                neutral: {
                    50: '#FFFFFF',
                    100: '#F2F2F8',
                    200: '#E4E4F1',
                    300: '#D7D7EA',
                    400: '#D7D7EA',
                    500: '#BCBCDC',
                    600: '#232343',
                    700: '#1C1C35',
                    800: '#151528',
                    900: '#0E0E1B',
                }
            },
        },
    },
});

const useEnhancedEffect =
    typeof window !== 'undefined' ? useLayoutEffect : useEffect;

export default function App() {
    const status = useScript('https://unpkg.com/feather-icons');

    useEnhancedEffect(() => {
        // Feather icon setup: https://github.com/feathericons/feather#4-replace
        // @ts-ignore
        if (typeof feather !== 'undefined') {
            // eslint-disable-next-line no-undef
            feather.replace();
        }
    }, [status]);

    ReactGA.initialize('G-LD19VNZ5F7');
    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search , title: location.pathname});
    }, [location]);

    return (
      <CssVarsProvider defaultMode="light" theme={theme} disableTransitionOnChange>
        <CssBaseline />
          <ErrorBoundary fallback={<ErrorPage/>}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <Outlet/>
            </LocalizationProvider>
          </ErrorBoundary>
      </CssVarsProvider>
  );
}
