import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import './core/axios-config'
import App from "./App";
import {StyledEngineProvider} from '@mui/joy/styles';
import dayjs from "dayjs";
import Loading from "./components/Loading";
import * as Sentry from "@sentry/react";
import ErrorPage from "./components/ErrorPage";

require('dayjs/locale/pt-br')
dayjs.locale('pt-br')

//Auth
const Auth = React.lazy(() => import("./pages/auth/Auth"));
const Login = React.lazy(() => import("./pages/auth/Login"));
const ForgetPassword = React.lazy(() => import( "./pages/auth/ForgetPassword"));
const Registration = React.lazy(() => import( "./pages/auth/Registration"));
const ForgetPasswordForm = React.lazy(() => import( "./pages/auth/ForgetPasswordForm"));
const CodeSent = React.lazy(() => import( "./pages/auth/CodeSent"));

//Admin
const Admin = React.lazy(() => import( "./pages/admin/Admin"));
const Users = React.lazy(() => import( "./pages/admin/users/Users"));
const Profile = React.lazy(() => import( "./pages/admin/profile/Profile"));
const Dashboard = React.lazy(() => import( "./pages/admin/Dashboard"));
const SuggestionsPage = React.lazy(() => import( "./pages/admin/suggestions/SuggestionsPage"));
const EssayListPage = React.lazy(() => import( "./pages/admin/essay-view/EssayListPage"));
const QuestionListPage = React.lazy(() => import( "./pages/admin/question-view/QuestionListPage"));
const ExamsPage = React.lazy(() => import( "./pages/admin/exams/ExamsPage"));
const ExamDetail = React.lazy(() => import( "./pages/admin/exams/ExamDetail"));
const QuestionTablePage = React.lazy(() => import( "./pages/admin/question-edit/QuestionTablePage"));
const QuestionDetail = React.lazy(() => import( "./pages/admin/question-edit/QuestionDetail"));
const CommentedAnswerDetail = React.lazy(() => import( "./pages/admin/question-edit/CommentedAnswerDetail"));
const DomainPage = React.lazy(() => import( "./components/domain/DomainPage"));
const CommentsPage = React.lazy(() => import( "./pages/admin/comments/CommentsPage"));

const router = createBrowserRouter([
    {
        path: "/",
        element: <Suspense fallback={<Loading/>}><App/></Suspense>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "",
                element: <Suspense fallback={<Loading/>}> <Admin/></Suspense>,
                children: [
                    {
                        path: "",
                        element: <Suspense fallback={<Loading/>}> <Dashboard/></Suspense>,
                    },
                    {
                        path: "question-view",
                        element: <Suspense fallback={<Loading/>}> <QuestionListPage/></Suspense>,
                    },
                    {
                        path: "essay-view",
                        element: <Suspense fallback={<Loading/>}> <EssayListPage/></Suspense>,
                    },
                    {
                        path: "users",
                        element: <Suspense fallback={<Loading/>}> <Users/></Suspense>,
                    },
                    {
                        path: "profile",
                        element: <Suspense fallback={<Loading/>}> <Profile/></Suspense>,
                    },
                    {
                        path: "settings/:domain",
                        element: <Suspense fallback={<Loading/>}> <DomainPage/></Suspense>,
                    },
                    {
                        path: "question",
                        element: <Suspense fallback={<Loading/>}> <QuestionTablePage/></Suspense>,
                    },
                    {
                        path: "question/:id",
                        element: <Suspense fallback={<Loading/>}> <QuestionDetail/></Suspense>,
                    },
                    {
                        path: "question/:id/commented-answer",
                        element: <Suspense fallback={<Loading/>}> <CommentedAnswerDetail/></Suspense>,
                    },
                    {
                        path: "exam",
                        element: <Suspense fallback={<Loading/>}> <ExamsPage/></Suspense>,
                    },
                    {
                        path: "exam/:id",
                        element: <Suspense fallback={<Loading/>}> <ExamDetail/></Suspense>,
                    },
                    {
                        path: "suggestion",
                        element: <Suspense fallback={<Loading/>}> <SuggestionsPage/></Suspense>,
                    },
                    {
                        path: 'comment',
                        element: <Suspense fallback={<Loading/>}> <CommentsPage/></Suspense>
                    }
                ]
            },
            {
                path: "",
                element: <Suspense fallback={<div>Carregando...</div>}> <Auth/></Suspense>,
                children: [
                    {
                        path: "login",
                        element: <Suspense fallback={<div>Carregando...</div>}> <Login/></Suspense>,
                    },
                    {
                        path: "registration",
                        element: <Suspense fallback={<div>Carregando...</div>}> <Registration/></Suspense>,
                    },
                    {
                        path: "forget-password",
                        element: <Suspense fallback={<div>Carregando...</div>}> <ForgetPassword/></Suspense>,
                    },
                    {
                        path: "forget-password/:code",
                        element: <Suspense fallback={<div>Carregando...</div>}> <ForgetPasswordForm/></Suspense>,
                    },
                    {
                        path: "code-sent",
                        element: <Suspense fallback={<div>Carregando...</div>}> <CodeSent/></Suspense>,
                    }
                ]
            }
        ]
    },
]);

Sentry.init({
    environment: process.env.REACT_APP_ENVIRONMENT,
    dsn: "https://c9c3237ed9acc075ba6d46e0cc6be9f6@o4506396229107712.ingest.sentry.io/4506507404247040",
    enabled: process.env.REACT_APP_ENABLE_SENTRY,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [process.env.REACT_APP_TRACING_TARGET],
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <RouterProvider router={router}/>
        </StyledEngineProvider>
    </React.StrictMode>
);


