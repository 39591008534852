import {useState} from "react";

export const getToken = () =>{
    const tokenString = localStorage.getItem('token');
    return tokenString ? JSON.parse(tokenString) : undefined;
}

export const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));
}

export const deleteToken = () => {
    localStorage.removeItem('token');
}


export function useToken() {
    const getToken = () =>{
        const tokenString = localStorage.getItem('token');
        return tokenString ? JSON.parse(tokenString) : undefined;
    }
    const [token, setToken] = useState(getToken());
    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken);
    }
    return {
        setToken: saveToken,
        token
    }
}
